




































































































import Vue from 'vue'
import {
  lessonStore,
  snackbarStore,
  wordStore,
  grammarStore,
} from '@/store'
import {
  Lesson,
  LessonType,
} from '@/types/lesson'
import {
  Id,
} from '@/types/base'
import {
  DataTableHeader,
} from 'vuetify'
import {
  Word,
} from '@/types/word'
import {
  Grammar,
} from '@/types/grammar'

export default Vue.extend({
  data: () => ({
    headers: [
      {
        text: `ID`,
        value: `id`,
      },
      {
        text: `Name`,
        value: `name`,
        class: `text-no-wrap`,
      },
      {
        text: `Translation`,
        value: `translation`,
      },
      {
        text: `Type`,
        value: `type`,
      },
      {
        text: `Data`,
        value: `typeDataIds`,
      },
      {
        text: ``,
        value: `actions`,
        sortable: false,
      },
    ] as DataTableHeader[],
  }),
  computed: {
    lessons (): (Lesson & Id)[] {
      return lessonStore.data
    },
  },
  watch: {
    lessons (lessons: (Lesson & Id)[]) {
      lessons.forEach((lesson) => {
        this.loadDataValues(lesson.typeDataIds, lesson.type)
      })
    },
  },
  async created () {
    try {
      await lessonStore.getAll()
    } catch (err) {
      snackbarStore.addSiteError({
        err,
        snackMessage: `Unable to load lessons`,
      })
    }
  },
  methods: {
    async deleteLesson (lesson: (Lesson & Id)) {
      if (!confirm(`Are you sure you want to delete "${lesson.name}"?`)) return
      try {
        await lessonStore.delete(lesson.id)
      } catch (err) {
        snackbarStore.addSiteError({
          err, snackMessage: `Error deleting lesson`,
        })
      }
    },
    loadDataValues (ids: string[], type: LessonType) {
      switch (type) {
      case `vocabulary`: wordStore.loadList(ids)
        break
      case `grammar`: grammarStore.loadList(ids)
        break
      }
    },
    getGrammarById (id: string): (Grammar | Id) {
      return grammarStore.dataById(id) || {
        id,
      }
    },
    getWordById (id: string): (Word | Id) {
      return wordStore.dataById(id) || {
        id,
      }
    },
  },
})
